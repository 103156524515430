<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Workflow Folder Detail" icon="fa-solid fa-sitemap"/>

    <div class="generalBorderlessBox">
        <div class="row">
            <div class="col-lg-5">
                <div class="generalContentBox border shadow-sm">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <span>
                                <Popper class="popperDark" arrow hover content="Back to Folder">
                                    <fa icon="angle-left" size="lg" class="me-3 isLink" @click="$router.push({ path: '/project' })" />
                                </Popper>
                            </span>
                            <span class="fw-bold">CR123 Workflow add policy no</span>
                        </div>
                        <div class="col-12 mb-3">
                            <div class="generalContentBox border innerBox mb-3">
                                <table class="table table-borderless mb-0" border="0">
                                    <tbody>
                                        <tr>
                                            <td width="100px" class="fw-bold">INITIATOR</td>
                                            <td class="text-end"><ProjStatus status="CIRCULATING" shred="NO" :showDesc="true" /></td>
                                        </tr>
                                        <tr>
                                            <td class="text-start">
                                                <ImgAvatar :isUser="true" id="" displayName="" isVerified="" isActive="" privilege="" align="left" width="60" height="60" />
                                            </td>
                                            <td>
                                                <div>
                                                    <span class="fw-bold">Seline Yong Lee Lee</span>
                                                    <span class="text-grey">
                                                        <fa icon="at" class="mx-1"/>Selina Yong
                                                    </span>
                                                </div>
                                                <div class="mb-2 small">selina.yong@gmail.com</div>
                                                <div class="fst-italic">Initiated at {{func.convDateTimeFormat(dateCreate, 'datetime')}}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="generalContentBox border innerBox">
                                <table class="table table-borderless mb-0" border="0">
                                    <tbody>
                                        <tr>
                                            <td width="100px" class="fw-bold">WORKFLOW</td>
                                            <td class="text-end fst-italic">REJECTED</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                Applied <span class="fst-italic">Change Request - AccMgr</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <div class="progress">
                                                    <!-- <div class="progress-bar progress-bar-striped" :style="{width: + fPProgress + '%'}">{{fPProgress}} % </div> -->
                                                    <div class="progress-bar progress-bar-striped" style="width: 60%">60% </div>
                                                </div>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="generalContentBox border shadow-sm">
                   <div class="row">
                        <div class="col-12 mb-2 fw-bold">PROGRESS</div>
                        <div class="col-12">
                            <div id="accordion">
                                <div class="card" v-for="(s, idx) in arrStep" :key="s">
                                    <div class="card-header p-0">
                                        <a class="btn" :class="s.status === 'PENDING' ? 'btn-secondary text-white' : ''" data-bs-toggle="collapse" :href="'#collapse' + idx" style="width: 100%">
                                            <div class="row text-start">
                                                <div class="col-lg-2">STEP {{ s.step }}</div>
                                                <div class="col-lg-7">
                                                    <table>
                                                        <tr>
                                                            <td rowSpan="2" class="align-baseline">
                                                                <span v-if="s.signeeType === 'initiator'"><fa icon="user-pen" size="lg" class="me-2" /></span>
                                                                <span v-if="s.signeeType === 'external'"><fa icon="user-slash" size="lg" class="me-2" /></span>
                                                                <span v-if="s.signeeType === 'team'"><fa icon="users" size="lg" class="me-2" /></span>
                                                                <span v-if="s.signeeType === 'member'"><fa icon="user" size="lg" class="me-3" /></span>
                                                            </td>
                                                            <td>{{ s.signee }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-grey small">{{ s.signeeEmail }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="col-lg-3 text-end">
                                                    <span v-if="s.status === 'APPROVED'" class="badge bg-success isLabel text-white">{{ s.status }}</span>
                                                    <span v-if="s.status === 'SKIPPED'" class="badge bg-secondary isLabel text-white">{{ s.status }}</span>
                                                    <span v-if="s.status === 'REJECTED'" class="badge bg-danger isLabel text-white">{{ s.status }}</span>
                                                    <span v-if="s.status === 'PENDING'" class="badge bg-brown isLabel text-white">{{ s.status }}</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div :id="'collapse' + idx" class="collapse" :class="idx === 0 ? 'show' : ''" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div v-if="s.status === 'REJECTED'">
                                                <span class="pe-2">{{func.convDateTimeFormat(s.dateCreate, 'datetime')}}</span>
                                                <span>{{ s.desc }}</span>
                                                <span class="float-end isLink" data-bs-toggle="modal" data-bs-target="#mdlAttachment"><fa icon="paperclip" size="lg" /></span>
                                            </div>
                                            <div v-else>{{func.convDateTimeFormat(s.dateCreate, 'datetime')}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                   </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: mdlAttachment --> 
    <div
        class="modal fade"
        id="mdlAttachment"
        tabindex="-1"
        aria-labelledby="Attachment"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Attachment</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <!-- <div v-if="arrStep" class="text-danger">{{ arrStep[4]["attachment"] }}</div> -->
                    
                    <!-- Carousel -->
                    <div id="demo" class="carousel slide carousel-dark" data-bs-ride="carousel">

                        <!-- Indicators/dots -->
                        <div class="carousel-indicators">
                            <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active"></button>
                            <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                        </div>
                        
                        <!-- The slideshow/carousel -->
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img src="../../assets/tmp_workflow/error1.png" alt="Error1" class="d-block" style="width:100%">
                            </div>
                            <div class="carousel-item">
                                <img src="../../assets/tmp_workflow/error2.png" alt="Error2" class="d-block" style="width:100%">
                            </div>
                        </div>
                        
                        <!-- Left and right controls/icons -->
                        <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon"></span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                            <span class="carousel-control-next-icon"></span>
                        </button>
                    </div>
                    <!-- End Carousel -->
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

</template>

<script>
import { ref, inject, onMounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import funcs from '@/functions/function'
import Popper from 'vue3-popper'
import ProjStatus from '@/components/ProjStatus.vue'

export default {
    name: 'WorkflowFolderDet',
    components: {
        TopNavigation, Alert, Popper, ProjStatus, ImgAvatar
    },
    setup () {
        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const { getTheme } = useStore() // reflect immediately
        const func = funcs

        const dateCreate = ref(null)
        const arrStep = ref(null)


        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        onMounted(() => {
            console.info('projId', route.params.projectId)
            
            // hardcoded
            dateCreate.value = "2023-12-01T07:21:18.000Z"
            arrStep.value = [
                { 
                    "step": 1,
                    "signeeType": "initiator",
                    "signee": "Initiator",
                    "status": "APPROVED",
                    "dateCreate": "2023-12-01T07:30:18.000Z",
                    "desc": "",
                    "hasAttachment": false
                },
                { 
                    "step": 2,
                    "signeeType": "member",
                    "signee": "Yen Nee",
                    "status": "APPROVED",
                    "dateCreate": "2023-12-01T09:00:18.000Z",
                    "desc": "",
                    "hasAttachment": false
                },
                { 
                    "step": 3,
                    "signeeType": "team",
                    "signee": "Support",
                    "status": "APPROVED",
                    "dateCreate": "2023-12-01T11:20:18.000Z",
                    "desc": "",
                    "hasAttachment": false
                },
                { 
                    "step": 3,
                    "signeeType": "team",
                    "signee": "CS",
                    "status": "SKIPPED",
                    "dateCreate": "2023-12-01T12:40:18.000Z",
                    "desc": "",
                    "hasAttachment": false
                },
                { 
                    "step": 4,
                    "signeeType": "external",
                    "signee": "Tan Chun Kiat",
                    "signeeEmail": "tanck123@gmail.com",
                    "status": "REJECTED",
                    "dateCreate": "2023-12-03T08:00:18.000Z",
                    "desc": "Rejected reason here",
                    "hasAttachment": true,
                    "attachment": [
                        { 
                            "imgId": 1,
                            "imgSrc": "error1.png",
                            "imgFilename": "error1.png"
                        },
                        { 
                            "imgId": 2,
                            "imgSrc": "error2.png",
                            "imgFilename": "error2.png"
                        }
                    ]
                },
                { 
                    "step": 5,
                    "signeeType": "external",
                    "signee": "Betrice Chan",
                    "signeeEmail": "betrice.chan@gmail.com",
                    "status": "PENDING",
                    "dateCreate": "2023-12-03T10:00:00.000Z",
                    "desc": "",
                    "hasAttachment": false
                }
            ]
        })

        return {
            route, router, alert, closeAlert, func, getTheme, 
            dateCreate, arrStep
        }
    }
}
</script>

<style>
.innerBox {
    background-color: var(--auth-box-bg);
}
</style>